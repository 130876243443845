import axios from "axios";
import { message } from "antd";

import { loginTokenKey, profileTokenKey } from "../constants";

axios.interceptors.request.use(
  (config: any) => {
    config.headers = {
      ...config.headers,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers":
        "Origin, X-Requested-With, Content-Type, Accept",
    };

    const token = localStorage.getItem(loginTokenKey);
    const profileToken = localStorage.getItem(profileTokenKey);

    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    if (profileToken) {
      config.headers["Authorization"] = "Bearer " + profileToken;
    }

    if (profileToken) {
      config.url = `${process.env.REACT_APP_JOB_SERVICE_BASE_URL}${config.url}`;

      
    } else {
      config.url = `${process.env.REACT_APP_API_HOST}${config.url}`;
      
    }
    console.log("profileToken", profileToken);
    console.log("token", token);
    console.log(
      "REACT_APP_JOB_SERVICE_BASE_URL",
      process.env.REACT_APP_JOB_SERVICE_BASE_URL
    );
    console.log("REACT_APP_API_HOST", process.env.REACT_APP_API_HOST);
    console.log("config", config);
    return config;
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      message.error({
        content: "User not unauthorized!",
        key: "unauthorized",
        duration: 2,
      });
      localStorage.clear();
      // window.location("/");
    }
    throw error;
  }
);
